<template>
  <Layout>
    <shop-wordpress-modal ref="shopWordpressRef" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-4">
              <h2 class="text-center">Zainstaluj wtyczkę w WordPress, a następnie ją połącz, aby móc zarządzać swoimi produktami.</h2>
            </div>

            <div class="py-2">
              <h5><b>WTYCZKA ECAT FREE:</b> <a href="/ECAT-ECOMMERCE.zip" class="text-danger">Pobierz</a> <a class="text-success clickable-element" @click="$refs.shopWordpressRef.openModal(getSelectedShopId)">(Kup wersję PRO)</a></h5>
              <h5>Status połączenia: <b :class="isPaired ? 'text-success' : 'text-danger'">{{ isPaired ? "Połączono" : "Niepołączono" }}</b></h5>
            </div>

            <div class="row py-5">
              <div class="col-12 col-lg-1" />

              <div class="col-12 col-lg-4">
                <h4>Instrukcja tekstowa dotycząca instalacji i połączenia wtyczki ECAT</h4>

                <div class="py-4">
                  <p>{{  $t('wordpress.instruction.activate.text') }}</p>
                </div>
              </div>

              <div class="col-12 col-lg-2">
              </div>

              <div class="col-12 col-lg-4">
                <h4>Instrukcja wideo dotycząca instalacji i połączenia wtyczki ECAT</h4>

                <div class="iframe-container">
                  <iframe style="width: 100% !important;" :src="$t('wordpress.instruction.activate.video')" height="480" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="Ecat eCommerce PL" />
                </div>
              </div>

              <div class="col-12 col-lg-1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue";
import ShopWordpressModal from "@/components/shop-wordpress-modal.vue";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {routerHelper} from "@/helpers/router-helper";

export default {
  name: "wordpress-not-activated",
  components: {Layout, ShopWordpressModal},

  data() {
    return {
      isPaired: false,
      intervalId: 0
    }
  },

  computed: {
    getSelectedShopId() {
      return this.$store ? this.$store.state.shop.shopId : "";
    }
  },

  methods: {
    async checkIsPaired() {
      if (!this.getSelectedShopId) {
        return
      }

      if (this.$store.getters['shop/shopIsVirtual'](this.getSelectedShopId)) {
        clearInterval(this.intervalId);
        routerHelper.push(this.$store.getters["wordpressnotactivated/getPath"])
        return
      }

      try {
        const {data} = await axios.get(`/shop/is-paired/by/shop-id/${this.getSelectedShopId}`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.isPaired = data.value
        if (this.isPaired) {
          clearInterval(this.intervalId);
          routerHelper.push(this.$store.getters["wordpressnotactivated/getPath"])
        }
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },

  created() {
    this.intervalId = setInterval(async () => {
      await this.checkIsPaired()
    }, 2000);
  }

}
</script>